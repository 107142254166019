import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, List, ListItem, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    maxWidth: '1200px',
    margin: '0 auto',
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    fontWeight: 'bold',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
    width: '60%',
  },
  addButton: {
    height: '48px',
    width: '200px',
  },
  list: {
    maxHeight: 400,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #e0e0e0',
  },
  headerCell: {
    width: '16.66%',
    fontWeight: 'bold',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100%',
    wordBreak: 'break-word',
  },
  cell: {
    width: '16.66%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1),
    borderRight: '1px solid #e0e0e0',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(0, 1),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.background.default,
    transition: 'background-color 0.3s ease',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    maxWidth: '1200px',
    margin: '0 auto',
  },
  headerScrolled: {
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  logo: {
    fontWeight: 'bold',
    fontSize: '2rem',
    color: '#000',
  },
  navLinks: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    marginRight: theme.spacing(4),
    color: '#000',
    textDecoration: 'none',
    fontSize: '1.1rem',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  loginButton: {
    border: '2px solid #000',
    padding: theme.spacing(0.75, 2.5),
    borderRadius: theme.spacing(0.5),
    color: '#000',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    transition: 'all 0.3s ease',
    whiteSpace: 'nowrap',
    width: '200px',
    '&:hover': {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
  mobileMenu: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  desktopMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

function App() {
  const classes = useStyles();
  const [profiles, setProfiles] = useState([]);
  const [inputUrls, setInputUrls] = useState('');
  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== isHeaderScrolled) {
        setIsHeaderScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isHeaderScrolled]);

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const addProfiles = async (e) => {
    e.preventDefault();
    if (inputUrls) {
      const urls = inputUrls.split('\n').filter(url => url.trim() !== '');
      try {
        const responses = await Promise.all(urls.map(url => 
          axios.post('/api/enrich-profile', { linkedinUrl: url.trim() })
        ));
        setProfiles([...profiles, ...responses.map(response => response.data)]);
        setInputUrls('');
      } catch (error) {
        console.error('Error enriching profiles:', error);
      }
    }
  };

  const copyToClipboard = () => {
    const headers = ['First Name', 'Last Name', 'Org', 'Email', 'Verified', 'LinkedIn URL'];
    const rows = profiles.map(p => 
      `${p.firstName}\t${p.lastName}\t${p.organization}\t${p.email}\t${p.emailValidation}\t${p.linkedinUrl}`
    );
    const text = [headers.join('\t'), ...rows].join('\n');
    navigator.clipboard.writeText(text);
  };

  const clearList = () => {
    setProfiles([]);
  };

  return (
    <>
      <header className={`${classes.header} ${isHeaderScrolled ? classes.headerScrolled : ''}`}>
        <Typography variant="h6" className={classes.logo}>
          👋 &nbsp;Heystack
        </Typography>
        <nav className={classes.desktopMenu}>
          <Typography variant="body1" className={classes.link}>
            Why Heystack
          </Typography>
          <Typography variant="body1" className={classes.link}>
            How It Works
          </Typography>
          <Typography variant="body1" className={classes.link}>
            Pricing
          </Typography>
          <Button className={classes.loginButton}>
            Log In
          </Button>
        </nav>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMobileMenuOpen}
          className={classes.mobileMenu}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={mobileMenuAnchorEl}
          keepMounted
          open={Boolean(mobileMenuAnchorEl)}
          onClose={handleMobileMenuClose}
        >
          <MenuItem onClick={handleMobileMenuClose}>Why Heystack</MenuItem>
          <MenuItem onClick={handleMobileMenuClose}>How It Works</MenuItem>
          <MenuItem onClick={handleMobileMenuClose}>Pricing</MenuItem>
          <MenuItem onClick={handleMobileMenuClose}>Log In</MenuItem>
        </Menu>
      </header>
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            Heystack 
          </Typography>
          <form onSubmit={addProfiles} className={classes.form}>
            <TextField
              className={classes.input}
              value={inputUrls}
              onChange={(e) => setInputUrls(e.target.value)}
              placeholder="Enter LinkedIn profile URLs (one per line)"
              variant="outlined"
              multiline
              rows={4}
            />
            <Button type="submit" variant="contained" color="primary" className={classes.addButton}>
              Add Profiles
            </Button>
          </form>
          <List className={classes.list}>
            <ListItem className={classes.row}>
              {['First Name', 'Last Name', 'Org', 'Email', 'Verified', 'LinkedIn URL'].map((header, index) => (
                <div key={index} className={classes.headerCell}>
                  <Typography>{header}</Typography>
                </div>
              ))}
            </ListItem>
            {profiles.map((profile, index) => (
              <ListItem key={index} className={`${classes.row} ${classes.card}`}>
                <Typography className={classes.cell}>{profile.firstName}</Typography>
                <Typography className={classes.cell}>{profile.lastName}</Typography>
                <Typography className={classes.cell}>{profile.organization}</Typography>
                <Typography className={classes.cell}>{profile.email}</Typography>
                <Typography className={classes.cell}>{profile.emailValidation}</Typography>
                <Typography className={classes.cell}>
                  <a href={profile.linkedinUrl} target="_blank" rel="noopener noreferrer">
                    {profile.linkedinUrl}
                  </a>
                </Typography>
              </ListItem>
            ))}
          </List>
          <div className={classes.buttonContainer}>
            <Button onClick={copyToClipboard} variant="contained" color="secondary" className={classes.button}>
              Copy to Clipboard
            </Button>
            <Button onClick={clearList} variant="outlined" className={classes.button}>
              Clear List
            </Button>
          </div>
        </Paper>
      </Container>
    </>
  );
}

export default App;