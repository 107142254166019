import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#22808d',
    },
    secondary: {
      main: '#5f5f59',
    },
    background: {
      default: '#fcfcf9',
    },
  },
  typography: {
    fontFamily: 'Space Grotesk, sans-serif',
  },
});

export default theme;